<template>
  <div class="kingMeal">
    <div v-title
         data-title="每周私享会"></div>
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-privateparties.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">每周一次</span>
            </div>
            <div class="title-item">
              <p>活动条件:</p>
              <span style="color:var(--li-colortext);">存款500元起，并且认证资料</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">当天存款后12小时内不能进入任何游戏场馆投注即可参加。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>

          <h3 style="color:var(--li-colortext);text-align:center;margin-top:20px">
            当前存款金额: {{ datalist.dpt || 0 }}元
          </h3>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="color: #fff;border-top-left-radius: 10px;border-right: 1px solid #e2d2bf;">
                  等级
                </td>
                <td style="color: #fff;">
                  <p>红利</p>
                  <p>比例</p>
                </td>
                <td style="color: #fff;">
                  <p>最高</p>
                  <p>彩金</p>
                </td>
                <!-- <td style="color: #fff;">
                  <p>最低</p>
                  <p>存款金额</p>
                </td> -->
                <td style="color: #fff;">
                  <p>流水</p>
                  <p>限制</p>
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">
                  <p>时间</p>
                  <p>限制</p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.message"
                  :key="index">
                <td>{{ item.vip_level }}</td>
                <td>{{ item.vip_detail[1].value }}</td>
                <td>{{ item.vip_detail[2].value }}</td>
                <!-- <td :rowspan="q.length" v-if="index == 0">
                  {{ item.vip_detail[0].value }}
                </td> -->
                <td>{{ item.vip_detail[3].value }}</td>
                <td>{{ item.vip_detail[4].value }}</td>
              </tr>
            </tbody>
          </table>

          <p style="font-size:12px;color:var(--td-colortext);margin-top: 15px;">
            当天存款后12小时内不能进入任何游戏场馆投注即可参加。
          </p>
          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                指定场馆：任何体育/真人/棋牌场馆。
              </li>
              <li>
                <span>2.</span>
                每周只可领取一次。
              </li>
              <li>
                <span>3.</span>
                当天存款后12小时内即可申请（不要进入任何游戏场馆投注后再申请否则视为无效）。
              </li>
              <li>
                <span>4.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>5.</span>
                需上周满足当前等级保级流水的2%有效流水才可参加。
                <p style="color:red">
                  例：VIP5保级流水250000*2%=5000有效流水
                </p>
              </li>
              <li>
                <span>6.</span>
                领取后必须在48小时内打完，如在指定的时间内未打够流水的有以下两种情况说明：
                <p style="color:red">1、有盈利：退回本金，并清除流水；</p>
                <p style="color:red">2、负盈利：直接清除流水。</p>
              </li>
              <li>
                <span>7.</span>
                该优惠活动成功申请后不能取消，必须打满流水才能提款。
              </li>
              <li>
                <span>8.</span>
                每位VIP1以上的会员每周可选择对应的游戏场馆申请1次彩金，有效投注额达到<br />
                【（充值金额+彩金）x15倍流水】即可提款。
                <p style="color:red">计算公式如下：</p>
                <p style="color:red">
                  例如VIP5会员申请贝宝体育彩金：充值金额1000元，
                </p>
                <p style="color:red">1000*35%=送彩金350元，有效投注额为：</p>
                <p style="color:red">(1000+350)×15=20250</p>
              </li>
              <li>
                <span>9.</span>
                体育存送、真人存送、电竞存送、棋牌存送等活动不能同时申请。
              </li>
              <li>
                <span>10.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，
                将取消其优惠资格。
              </li>
              <li>
                <span>11.</span>
                10.本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>12.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>13.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.youxi"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'

import { getdata } from '../network/api'
export default {
  name: '',
  components: {},
  data() {
    return {
      kingMealData: '',
      q: '',
      showPicker: false,
      datalist: [],
      cueTheme: '',
    }
  },

  mounted() {
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },
  methods: {
    confirm(e) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'VipDptBonus',
                  gameid: e.id,
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'VipDptBonus',
          //       gameid: e.id,
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'VipDptBonus',
              gameid: e.id,
            },
          })
        }
      })

      this.showPicker = false
    },
    cancel() {
      this.showPicker = false
    },
    getdata() {
      let data = '?type=vipBonus'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
        }
      })
    },
    applyWay() {
      this.showPicker = true
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="less">
@import url('../assets/url.less');
@import '../assets/css/rulestyle.less';

body {
  background-color: #f6f7fc;
}

@import url('../assets/url.less');

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000 !important;
}

.kingMeal {
  // margin-top: 42px;
  .topBgc {
    margin: 0 10px;

    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 1px 6px 0px #d7dae2;

    .arrow {
      margin-top: 30px;
      margin-left: 20px;
      width: 25px;
    }

    .mathbox {
      font-family: PingFang SC;
      border: 1px solid #e9e9e9;
      position: relative;

      .finishpe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      .mathbox-item {
        display: flex;
        align-items: center;
        font-size: 12px;

        &:nth-child(1) {
          border-bottom: 1px solid #e9e9e9;
        }

        .title {
          padding: 15px 5px;
          height: 100%;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          background: linear-gradient(90deg, #3d73fa, #63b3fc);
          border-right: 1px solid #e9e9e9;
        }

        .content {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex: 1;
          text-align: center;
          font-family: PingFang SC;
          font-weight: 400;
          color: #283d68;

          img {
            width: 20px;
            vertical-align: middle;
          }
        }
      }
    }

    .rule {
      .rulebox {
        display: flex;
        align-items: center;

        > div {
          line-height: 1.5em;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;

          > img {
            max-width: 80px;
          }
        }
      }

      .primary_color {
        font-family: PingFang SC;
        font-weight: 400;
        color: #303030;
      }
    }
  }
}

.btn-box {
  padding-bottom: 5px;

  > div {
    font-family: PingFang SC;
    font-weight: 400;

    &:nth-child(1) {
      height: 40px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      margin: 10px 0px 15px 0px;
    }

    &:nth-child(2) {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #2a6afd;
      color: #418beb;
      margin: 15px 0px 15px 0px;
    }
  }
}
</style>
